.dse-banner-animated {
    position: relative;
    top: 80px;
}
.dse-banner-animated div {
    position: absolute;
    transform: rotateX(-90deg);
    opacity: 0;
    animation-timing-function: ease;
}
.dse-banner-animated div:nth-child(1) {
    animation: rollDown 10s forwards infinite;
}
.dse-banner-animated div:nth-child(2) {
    animation: rollDown2 10s forwards infinite;
}
.dse-banner-animated div:nth-child(3) {
    animation: rollDown3 10s forwards infinite;
}
@keyframes rollDown {
    0% {
        top: 50px;
        transform: rotateX(30deg);
        opacity: 0;
   }
    11% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
   }
    22% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
   }
    33% {
        top: -200px;
        transform: rotateX(-90deg);
   }
}
@keyframes rollDown2 {
    33% {
        top: 50px;
        transform: rotateX(30deg);
        opacity: 0;
   }
    44% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
   }
    55% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
   }
    66% {
        top: -200px;
        transform: rotateX(-90deg);
   }
}
@keyframes rollDown3 {
    66% {
        top: 50px;
        transform: rotateX(30deg);
        opacity: 0;
   }
    77% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
   }
    88% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
   }
    99% {
        top: -200px;
        transform: rotateX(-90deg);
   }
}
.dse-benefit-section-down {
    display: block;
    animation: animate-forward 500ms linear;
}
@media (min-width: 640px) {
    .dse-benefit-section-down {
        animation: animate-forward-sm 500ms linear;
   }
}

.dse-benefit-section-up {
    animation: animate-backward 500ms linear forwards;
}
@media (min-width: 640px) {
    .dse-benefit-section-up {
        animation: animate-backward-sm 500ms linear forwards;
   }
}
@keyframes animate-forward {
    0% {
        height: 0px;
   }
    100% {
        height: 338px;
   }
}
@keyframes animate-backward {
    0% {
        height: 338px;
   }
    100% {
        height: 0px;
        display: none;
   }
}
@keyframes animate-forward-sm {
    0% {
        height: 0px;
   }
    100% {
        height: 408px;
   }
}
@keyframes animate-backward-sm {
    0% {
        height: 408px;
   }
    100% {
        height: 0px;
        display: none;
   }
}
.dse-banner-border {
    padding: 1px;
    background: #06071b;
    box-shadow: none;
    opacity: 0;
    border-radius: 12px;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-delay: 100ms;
    transition-timing-function: ease-in-out;
}
.dse-banner-border-blue {
    box-shadow: 0px 0px 32px rgba(0, 203, 249, 0.24);
    background: -webkit-linear-gradient(#09c3e7, #7669f0);
}
.dse-banner-border-pink {
    box-shadow: 0px 0px 32px rgba(253, 104, 30, 0.24);
    background: -webkit-linear-gradient(#9c2979, #f15e75);
}
.dse-banner-border-orange {
    box-shadow: 0px 0px 32px rgba(255, 95, 109, 0.24);
    background: -webkit-linear-gradient(#ff9f38, #fe5c44);
}
.dse-banner-border-show {
    opacity: 1;
}
.dse-banner-mesh {
    opacity: 0;
    height: 88px;
    width: 87px;
    transition-property: transform, opacity, width, height, margin;
    transition-duration: 500ms, 500ms;
    transition-delay: 600ms, 600ms;
    transition-timing-function: ease-in-out;
}
.dse-banner-mesh-one {
    transform: translate(-200px, -200px);
}
.dse-banner-mesh-two {
    transform: translate(0, -200px);
}
.dse-banner-mesh-three {
    transform: translate(200px, -200px);
}
.dse-banner-mesh-four {
    transform: translate(-200px, 0px);
}
.dse-banner-mesh-six {
    transform: translate(200px, 0px);
}
.dse-banner-mesh-seven {
    transform: translate(-200px, 200px);
}
.dse-banner-mesh-eight {
    transform: translate(0, 200px);
}
.dse-banner-mesh-nine {
    transform: translate(200px, 200px);
}
.dse-banner-mesh-show {
    transform: translate(0, 0);
    opacity: 1;
}
.dse-animate-benefit {
    transition-property: transform;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
}
.dse-translate-opacity {
    transition-property: opacity;
    transition-duration: 800ms;
    transition-timing-function: ease-in-out;
}
@media (min-width: 64rem) {
    .dse-banner-mesh {
        height: 112px;
        width: 112px;
   }
}
